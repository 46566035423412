<template>
  <section>
    <b-row class="mb-2">
      <b-col>
        <h1>{{ $t("Publicidad") }}</h1>
        <h5 class="text-primary">
          {{ $t("confAds") }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="match-height mt-2">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showData"
        >
          <b-card>
            <b-card-body class="mt-2 mb-2">
              <b-form @submit.prevent="updateData">
                <b-row class="infoContent mb-4">
                  <b-col>
                    <label for="adsPenetration">{{ $t("gestPubli") }}
                    </label>
                  </b-col>
                  <b-col>
                    <b-form-select
                      v-model="selected"
                      :options="options"
                    />
                  </b-col>
                </b-row>
                <b-row class="infoContent mb-4">
                  <b-col>
                    <label for="externalFillRate">{{
                      $t("ads.label2")
                    }}</label>
                  </b-col>
                  <b-col>
                    <b-form-input
                      id="externalFillRate"
                      v-model="externalFillRate"
                      type="number"
                      :min="0"
                      step="0.01"
                      autocomplete="new-password"
                    />
                    <small class="text-primary">{{ $t("ads.label2") }}</small>
                  </b-col>
                </b-row>
                <b-row class="infoContent mb-4">
                  <b-col>
                    <label for="prerollFillRate">{{
                      $t("ads.label3")
                    }}</label>
                  </b-col>

                  <b-col>
                    <b-form-input
                      id="prerollFillRate"
                      v-model="prerollFillRate"
                      type="number"
                      :min="0"
                      step="0.01"
                      autocomplete="new-password"
                    />
                    <small class="text-primary">{{ $t("ads.label3") }}</small>
                  </b-col>
                </b-row>
                <b-row class="infoContent mb-4">
                  <b-col>
                    <label for="midrollFillRate">{{
                      $t("ads.label4")
                    }}</label>
                  </b-col>
                  <b-col>
                    <b-form-input
                      id="midrollFillRate"
                      v-model="midrollFillRate"
                      type="number"
                      :min="0"
                      step="0.01"
                      autocomplete="new-password"
                    />
                    <small class="text-primary">{{ $t("ads.label4") }}</small>
                  </b-col>
                </b-row>
                <b-row class="infoContent mb-4">
                  <b-col>
                    <label for="postrollFillRate">{{
                      $t("ads.label5")
                    }}</label>
                  </b-col>
                  <b-col>
                    <b-form-input
                      id="postrollFillRate"
                      v-model="postrollFillRate"
                      type="number"
                      :min="0"
                      step="0.01"
                      autocomplete="new-password"
                    />
                    <small class="text-primary">{{ $t("ads.label5") }}</small>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col style="text-align: end;">
                    <b-button
                      type="submit"
                      variant="success"
                    >
                      {{
                        $t("dataGeneric.save")
                      }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormInput,
  BOverlay,
  BForm,
  BFormSelect,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BForm,
    BCardBody,
    BFormInput,
    BOverlay,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      adsPenetration: null,
      prerollFillRate: null,
      midrollFillRate: null,
      onlyStripe: null,
      showData: false,
      id: null,
      postrollFillRate: null,
      externalFillRate: null,
      selected: 'MIX',
      options: [
        { value: 'MIX', text: this.$t('dataGeneric.mixed') },
        { value: 'INT', text: this.$t('dataGeneric.internal') },
        { value: 'EXT', text: this.$t('dataGeneric.external') },
      ],
    }
  },
  watch: {
    selected(newValue, oldValue) {
      if (newValue !== oldValue) {
        switch (newValue) {
          case 'MIX':
            this.externalFillRate = 0.5
            break
          case 'INT':
            this.externalFillRate = 0

            break
          case 'EXT':
            this.externalFillRate = 1

            break

          default:
            break
        }
      }
    },
  },
  mounted() {
    if (!getUserData().isSuperuser) {
      this.$router.push({ name: 'dashboard' })
    }

    this.getClient()
  },
  methods: {
    getClient() {
      const userData = getUserData()
      axios
        .post('', {
          query: `query{
              allClients(clientId:"${userData.profile.client.clientId}"){
                edges {
                  node {
                    id
                    adsPenetration        
                    externalFillRate
                    prerollFillRate
                    midrollFillRate
                    postrollFillRate
                    
                  }
                }
              }
            }
  `,
        })
        .then(result => {
          messageError(result, this)

          const response = result.data.data.allClients.edges[0]
          this.id = response.node.id
          this.adsPenetration = response.node.adsPenetration
          this.prerollFillRate = response.node.prerollFillRate
          this.midrollFillRate = response.node.midrollFillRate
          this.postrollFillRate = response.node.postrollFillRate
          this.externalFillRate = response.node.externalFillRate

          switch (this.externalFillRate) {
            case 0.5:
              this.selected = 'MIX'
              break
            case 0:
              this.selected = 'INT'

              break
            case 1:
              this.selected = 'EXT'

              break

            default:
              break
          }
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
        })
    },

    updateData(event) {
      this.showData = true
      event.preventDefault()
      axios
        .post('', {
          query: `
              mutation{
              updateClient(id:"${this.id}",input:{
                ${this.adsPenetration == null
    ? ''
    : `adsPenetration:${this.adsPenetration},`
}
                ${this.prerollFillRate == null
    ? ''
    : `prerollFillRate:${this.prerollFillRate},`
}
                ${this.midrollFillRate == null
    ? ''
    : `midrollFillRate:${this.midrollFillRate},`
}
                ${this.postrollFillRate == null
    ? ''
    : `postrollFillRate:${this.postrollFillRate},`
}
                ${this.externalFillRate == null
    ? ''
    : `externalFillRate:${this.externalFillRate},`
}             
                
              }) {              
                client{
                  name
                  id
                }
              }
            }
          `,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.ads'), 1, this)

          this.showData = false
        })
        .catch(() => {
          showToast(this.$t('code.errorAds'), 2, this)
          this.showData = false
        })
    },

  },
}
</script>
